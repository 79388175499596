/**@jsx jsx */
import React, { useState, useRef, useEffect, cloneElement } from 'react'
import { Tabs, useTabState } from '@bumaga/tabs'
import { motion, AnimatePresence } from 'framer-motion'
import { Box, Flex, Button, jsx } from 'theme-ui'

export const Tab: React.FC = ({ children }) => {
  const { isActive, onClick } = useTabState()

  return (
    <Button
      onClick={onClick}
      sx={{
        display: 'inline',
        margin: '0 10px 0 10px',
        fontFamily: 'Futura Bold',
        fontSize: ['12px', '24px'],
        color: '#1D1D1D',
        outline: 'none',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: '#fff',

        '::after': {
          content: '" "',
          display: 'block',
          width: isActive ? '100%' : '0',
          height: '3px',
          background: '#147CED',
          transition: 'width .3s',
        },
      }}
    >
      {children}
    </Button>
  )
}

const PanelList = ({ state, children }) => {
  const panelRef = useRef()
  const [height, set] = useState(0)
  const [activeIndex] = state

  useEffect(() => {
    panelRef.current && set(panelRef.current.offsetHeight)
  }, [activeIndex, set])

  return (
    <AnimatePresence animate={{ height }} initial={false}>
      <motion.div
        ref={panelRef}
        key={activeIndex}
        initial={{ opacity: 0, x: -32 }}
        animate={{
          opacity: 1,
          x: 0,
          transition: { delay: 0.2, ease: 'easeInOut', duration: 0.2 },
        }}
        exit={{
          opacity: 0,
          x: 32,
          transition: { ease: 'easeInOut', duration: 0.2 },
        }}
      >
        {cloneElement(children[activeIndex], { active: true })}
      </motion.div>
    </AnimatePresence>
  )
}

const MainTabs = ({ tabHeaderConfig, panels }) => {
  const state = useState(0)

  return (
    <Tabs state={state}>
      <Flex
        sx={{
          flexDirection: ['column'],
          width: '100%',
          pt: ['24px'],
          pr: [0, '32px'],
        }}
      >
        <Flex
          sx={{
            pb: '24px',
            mx: ['none', 'auto'],
          }}
        >
          {tabHeaderConfig.map((name: string) => (
            <Tab key={name}>{name}</Tab>
          ))}
        </Flex>
        <PanelList state={state}>{panels}</PanelList>
      </Flex>
    </Tabs>
  )
}

export default MainTabs
